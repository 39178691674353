import React from "react";
import Layout from "../components/Layout";
import Grid from "../components/posts/Grid";
import "../pages/style.scss";

export default ({ pageContext }) => {
  return (
    <Layout>
      <section className="hero is-light">
        <div className="hero-body showcase-container">
          <div className="container has-text-centered">
            <span className="title is-size-1-desktop is-size-3-mobile has-text-weight-semibold main-title">
              Blog
            </span>
          </div>
        </div>
      </section>
      <section className="section is-desktop">
        <Grid
          data={pageContext.group.map(({ node }) => node)}
          cols={2}
          pageContext={pageContext}
        />
      </section>
    </Layout>
  );
};
