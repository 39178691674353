import React from "react";
import { Link } from "gatsby";
import { chunkArray } from "../../common/utils";

const NavLink = ({ test, className, text, url }) => (
  <Link className={className} to={url} disabled={test}>
    {text}
  </Link>
);

export default ({ data, cols, pageContext }) => {
  const { index, first, last, pageCount, pathPrefix } = pageContext;
  const previousUrl =
    `/${pathPrefix}/` + (index - 1 === 1 ? "" : (index - 1).toString());
  const nextUrl = `/${pathPrefix}/${(index + 1).toString()}`;

  return data && data instanceof Array ? (
    <div className="container blog-grid">
      {chunkArray(data, cols).map((columns, i) => (
        <Row key={i} index={i} columns={columns} />
      ))}

      {pageCount > 1 && (
        <nav
          className="pagination is-rounded is-right"
          role="navigation"
          aria-label="pagination"
        >
          <NavLink
            className={"pagination-previous"}
            test={first}
            url={previousUrl}
            text="Previous"
          />
          <NavLink
            className={"pagination-next"}
            test={last}
            url={nextUrl}
            text="Next page"
          />
          {/* <ul className="pagination-list">
            <li>
              <a className="pagination-link" aria-label="Goto page 1">
                1
              </a>
            </li>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <a className="pagination-link" aria-label="Goto page 45">
                45
              </a>
            </li>
            <li>
              <a
                className="pagination-link is-current"
                aria-label="Page 46"
                aria-current="page"
              >
                46
              </a>
            </li>
            <li>
              <a className="pagination-link" aria-label="Goto page 47">
                47
              </a>
            </li>
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
            <li>
              <a className="pagination-link" aria-label={`Goto page ${pageCount}`}>
                {pageCount}
              </a>
            </li>
          </ul> */}
        </nav>
      )}
    </div>
  ) : null;
};

const Row = ({ columns, index }) => (
  <div className="columns">
    {columns &&
      columns.map((tileData, i) => (
        <BlogCard key={index + "-" + (tileData.id || i)} {...tileData} />
      ))}
  </div>
);

const BlogCard = ({ title, slug, featured_media, author, date, excerpt }) => (
  <div className="card blog-card is-desktop">
    <div className="card-image">
      <figure className="image is-2by1">
        <img
          src={
            featured_media
              ? featured_media.localFile.childImageSharp.fixed.src
              : "https://bulma.io/images/placeholders/1280x960.png"
          }
          alt="Blog"
        />
      </figure>
    </div>
    <div className="card-content">
      <div className="media">
        <div className="media-content">
          <p>
            <Link className="title is-5" to={`/post/${slug}`}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
          </p>
          <p className="subtitle is-6">
            <time dateTime="2016-1-1">{date}</time> |{" "}
            <span>{author && author.name}</span>
          </p>
        </div>
      </div>

      <div className="content">
        <span
          dangerouslySetInnerHTML={{
            __html: excerpt,
          }}
        />
        <br />
      </div>
    </div>
  </div>
);
